import PropTypes from "prop-types";
import { m } from "framer-motion";
// import { useState } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Grid,
  List,
  // Button,
  Popover,
  ListItem,
  ListSubheader,
  CardActionArea,
  // InputAdornment,
  // Autocomplete,
  // // TextField,
  // Paper,
  // InputBase,
  // IconButton,
  Stack,
  Typography,
} from "@mui/material";
// components
import Iconify from "../../components/Iconify";
import LogoReverseFull from "src/components/LogoReverseFull";
import useAuth from "src/hooks/useAuth";
import { PATH_AUTH } from "src/routes/paths";
import CartWidget from "src/sections/@dashboard/e-commerce/CartWidget";
import AccountPopoverMain from "./AccountPopoverMain";
import WishlistWidget from "src/sections/@dashboard/e-commerce/WishlistWidget";
// import { convertToUrlSafeWithPlus } from "src/utils/utils";
import SearchBar from "./SearchBar";

// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": {
    opacity: 0.48,
    textDecoration: "none",
  },
}));

const SubLinkStyle = styled((props) => (
  <ListItem sx={{ p: 0 }}>
    <Link target="_blank" rel="noopener" {...props}>
      {props.children}
    </Link>
  </ListItem>
))(({ theme }) => ({
  ...theme.typography.body2,
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(3),
  color: theme.palette.text.secondary,
  transition: theme.transitions.create("color"),
  "&:hover": {
    color: theme.palette.text.primary,
  },
}));

// ----------------------------------------------------------------------

MenuDesktop.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
  // const { pathname } = useLocation();

  // const navigate = useNavigate();

  const { isAuthenticated } = useAuth();

  // const [open, setOpen] = useState(false);


  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <LogoReverseFull />

      <SearchBar 
      
      />

      <WishlistWidget />

      {isAuthenticated ? (
        <Box sx={{ mx: 2 }}>
          <AccountPopoverMain />
        </Box>
      ) : (
        <SignIn />
      )}

      <CartWidget />

    </Box>
  );
}


// ----------------------------------------------------------------------

const RootStyle = styled(RouterLink)(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  textDecoration: 'none',
  // position: 'fixed',
  alignItems: 'center',
  // top: theme.spacing(16),
  // height: theme.spacing(5),
  // paddingLeft: theme.spacing(2),
  // paddingRight: theme.spacing(2),
  // paddingTop: theme.spacing(1.25),
  // boxShadow: theme.customShadows.z20,
  // color: theme.palette.text.primary,
  // backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72, backgroundColor: 'primary.main' },
}));

// ----------------------------------------------------------------------

function SignIn() {

  return (
      <RootStyle to={PATH_AUTH.login}>

          <Stack direction="row" spacing={1} sx={{mx:1, alignItems:'center'}}>
              <Iconify icon={'eva:person-outline'} width={24} height={24} 
                  sx={{ color: "secondary.light" }}
                  />
              <Stack>
              <Typography color='secondary.light' sx={{fontSize: 14}} noWrap>Sign In Account</Typography>
              {/* <Typography color='secondary.light' sx={{fontSize: 14, fontWeight: 700}}>
                  Account
              </Typography> */}
              </Stack>
          </Stack>

      </RootStyle>
  );
}
// ----------------------------------------------------------------------

MenuDesktopItem.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

function MenuDesktopItem({ item, isHome, isOpen, isOffset, onOpen, onClose }) {
  const { pathname } = useLocation();

  const { title, path, children } = item;

  const isActive = (path) => pathname === path;

  if (children) {
    return (
      <>
        <LinkStyle
          onClick={onOpen}
          sx={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            ...(isHome && { color: "common.white" }),
            ...(isOffset && { color: "text.primary" }),
            ...(isOpen && { opacity: 0.48 }),
          }}
        >
          {title}
          <Iconify
            icon={
              isOpen
                ? "eva:arrow-ios-upward-fill"
                : "eva:arrow-ios-downward-fill"
            }
            sx={{ ml: 0.5, width: 16, height: 16 }}
          />
        </LinkStyle>

        <Popover
          open={isOpen}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 80, left: 0 }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={onClose}
          PaperProps={{
            sx: {
              px: 3,
              pt: 5,
              pb: 3,
              right: 16,
              m: "auto",
              borderRadius: 2,
              maxWidth: (theme) => theme.breakpoints.values.lg,
              boxShadow: (theme) => theme.customShadows.z24,
            },
          }}
        >
          <Grid container spacing={3}>
            {children.map((list) => {
              const { subheader, items } = list;

              return (
                <Grid
                  key={subheader}
                  item
                  xs={12}
                  md={subheader === "Dashboard" ? 6 : 2}
                >
                  <List disablePadding>
                    <ListSubheader
                      disableSticky
                      disableGutters
                      sx={{
                        display: "flex",
                        lineHeight: "unset",
                        alignItems: "center",
                        color: "text.primary",
                        typography: "overline",
                      }}
                    >
                      <IconBullet type="subheader" /> {subheader}
                    </ListSubheader>

                    {items.map((item) => (
                      <SubLinkStyle
                        key={item.title}
                        href={item.path}
                        sx={{
                          ...(isActive(item.path) && {
                            color: "text.primary",
                            typography: "subtitle2",
                          }),
                        }}
                      >
                        {item.title === "Dashboard" ? (
                          <CardActionArea
                            sx={{
                              py: 5,
                              px: 10,
                              borderRadius: 2,
                              color: "primary.main",
                              bgcolor: "background.neutral",
                            }}
                          >
                            <Box
                              component={m.img}
                              whileTap="tap"
                              whileHover="hover"
                              variants={{
                                hover: { scale: 1.02 },
                                tap: { scale: 0.98 },
                              }}
                              src="/assets/illustrations/illustration_dashboard.png"
                            />
                          </CardActionArea>
                        ) : (
                          <>
                            <IconBullet />
                            {item.title}
                          </>
                        )}
                      </SubLinkStyle>
                    ))}
                  </List>
                </Grid>
              );
            })}
          </Grid>
        </Popover>
      </>
    );
  }

  if (title === "Documentation") {
    return (
      <LinkStyle
        href={path}
        target="_blank"
        rel="noopener"
        sx={{
          ...(isHome && { color: "common.white" }),
          ...(isOffset && { color: "text.primary" }),
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  return (
    <LinkStyle
      to={path}
      component={RouterLink}
      end={path === "/"}
      sx={{
        ...(isHome && { color: "common.white" }),
        ...(isOffset && { color: "text.primary" }),
        "&.active": {
          color: "primary.main",
        },
      }}
    >
      {title}
    </LinkStyle>
  );
}

// ----------------------------------------------------------------------

IconBullet.propTypes = {
  type: PropTypes.oneOf(["item", "subheader"]),
};

function IconBullet({ type = "item" }) {
  return (
    <Box sx={{ width: 24, height: 16, display: "flex", alignItems: "center" }}>
      <Box
        component="span"
        sx={{
          ml: "2px",
          width: 4,
          height: 4,
          borderRadius: "50%",
          bgcolor: "currentColor",
          ...(type !== "item" && {
            ml: 0,
            width: 8,
            height: 2,
            borderRadius: 2,
          }),
        }}
      />
    </Box>
  );
}
